@font-face {
  font-family: 'basier';
  src: url('../font/BasierCircle-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'basier';
  src: url('../font/BasierCircle-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'basier';
  src: url('../font/BasierCircle-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'basier';
  src: url('../font/BasierCircle-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

$primary: #4dd0bb;
$secundary: #0f1b35;
* {
  box-sizing: border-box;
  font-family: 'basier', sans-serif;
  transition: all 0.3s ease, background-position 1ms;
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: #e8e7ea;
}
#children {
  background-color: #e8e7ea;
  position: relative;
  min-height: 440px;
  padding: 30px 0;
}
#top {
  position: relative;
  background-color: $secundary;
}
#top::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/intro-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #fff;
  @media only screen and (max-width: 769px) {
    background-image: url('../images/intro-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(2);
  }
}
#noise {
  background-image: url('../images/Noise-2.png');
}

#header {
  position: relative;
  padding: 16px 0;
  a {
    padding: 8px;
    color: #eee;
    text-decoration: none;
    h1 {
      margin: 0;
      font-size: 32px;
    }
  }
  .right-message {
    font-size: 16px;
    color: #eee;
  }
}

.card {
  border: 0;
}
#intro {
  position: relative;
  height: 350px;
  color: #fff;
  .row {
    position: relative;
    max-width: 756px;
    margin: 0 auto;
    display: grid !important;
    grid-template-columns: 4fr 1fr;
    gap: 0px;
    width: 100%;
    justify-content: center;
  }
  h1 {
    color: #eee !important;
  }
  .input-wrap {
    position: relative;
    padding: 0;
    input {
      padding: 12px 26px;
      border-radius: 8px 0 0 8px;
      background-color: #fff !important;
      border: 1px solid #eeeeee !important;
      color: #6c757d;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(87, 76, 221, 0.5) !important;
      }
    }
    .floating-results {
      position: absolute;
      z-index: 100;
      top: 100%;
      width: 100%;
      list-style-type: none;
      padding: 0;
      box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      -webkit-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      li {
        &:first-child {
          border-radius: 8px 8px 0 0;
        }
        &:last-child {
          border-radius: 0 0 8px 8px;
        }
        background-color: #fff;
        &:hover {
          background-color: rgb(234, 234, 234);
          color: #555;
        }
        a {
          text-decoration: none;
          display: block;
          padding: 10px;
          width: 100%;
          color: #3f4243;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
  button {
    color: #eee;
    background-color: $primary;
    border-radius: 0 8px 8px 0;
    font-weight: 700;
    border: transparent;
    letter-spacing: 0.15rem;
    &:hover {
      background-color: #349888;
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(87, 76, 221, 0.5) !important;
    }
  }
}
#footer {
  border-top: 1px solid rgb(219, 219, 219);
  color: #555;
  padding: 55px 0;
  .left {
    padding-left: 30px;
  }
  .logo {
    display: block;
    padding-left: 30px;
    font-size: 21px;
    font-weight: 700;
  }
  .wrap {
    display: flex;
    justify-content: right;
    align-items: center;
    a {
      display: flex;
      justify-content: right;
      align-items: center;
      text-align: center;
      color: #333;
      text-decoration: none;
      padding: 20px;
      padding-right: 27px;
      i {
        margin-right: 4px;
        font-size: 21px;
        &:hover {
          color: #4dd0bb;
        }
      }
      &:hover {
        color: #4dd0bb;
      }
    }
  }
}
#categorie {
  display: flex;
  justify-content: center;
  .row {
    display: grid !important;
    row-gap: 24px !important;
    column-gap: 0px !important;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .categorie {
    text-align: center;
    background-repeat: no-repeat;
    .name {
      font-weight: 500;
      margin: 16px 0;
    }
    .span {
      margin: 0 auto;
      max-width: 120px !important;
      padding: 10px 20px;
      background-color: #fff;
      border: 2px solid #4dd0bb;
      color: #4dd0bb;
      border-radius: 4px;
      font-size: 14px;
    }

    a {
      box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      -webkit-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      -moz-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      background-color: #fff;
      border-radius: 4px;
      color: #333 !important;
      text-decoration: #aaa;
      display: block !important;
      padding: 27.5px;
      &:hover {
        background-color: rgba(240, 240, 240, 0.3);
        .span {
          background-color: #4dd0bb;
          color: #fff;
        }
      }
      i {
        color: $primary;
        font-size: 60px;
      }
    }
  }
}

.result-search {
  .category-content {
    &:hover {
      background-color: rgba(240, 240, 240, 0.3);
      color: #555;
    }
    .category-head {
      h3 {
        margin: 0;
        padding: 0;
      }
    }
    &:hover {
      .category-head {
        color: $primary !important;
      }
    }
    .category-wrap a:hover {
      color: #aaa !important;
    }
  }
}

.archive-posts-row {
  row-gap: 24px;
  .category-wrap {
    div {
      border-radius: 4px !important;
    }
    .category-content {
      background-color: #fff;
      padding: 30px;
    }
    .category-head {
      display: block;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      border-radius: 0 !important;
      &.articles-head {
        color: #4dd0bb;
      }
    }
    .ul {
      margin-top: 20px;
    }
    .listGroup {
      background-color: transparent;
      padding: 0px;
      border: 0px;
    }
    a {
      color: #777;
      display: block;
      padding: 8px 0;
      text-decoration: none;
      &:hover {
        color: #68ebd5;
      }
      &.active {
        color: $primary;
      }
    }
  }
}

.post-wrap {
  .link-card-post {
    text-decoration: none !important;
  }

  &.archive-posts {
    row-gap: 24px;
    display: flex;
    flex-direction: column;
    .no-cat {
      padding: 30px 0 40px 0;
    }
    .card-post {
      box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      -webkit-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      -moz-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
      background-color: #fff;
      padding: 30px;
      border-radius: 4px !important;

      &:hover {
        background-color: rgba(240, 240, 240, 0.3);
        color: #555;
      }
      &:hover {
        .category-head {
          border-bottom: 1px solid #d6d6d6;
          color: $primary;
        }
      }
      .category-head {
        color: #333;
        display: block;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
      }
      .content-wrap {
        color: #777;
        margin-top: 10px;
        align-items: center;
      }
      .text-description {
        overflow: hidden;
        max-height: 50px;
      }
      .ratings {
        text-align: right;
        padding: 0;
        border-left: 1px solid #eee;
      }
    }
  }
}

.category-wrap {
  .category-content {
    box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
    -webkit-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
    -moz-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
    border-radius: 4px;
    padding: 30px;
    background-color: #fff;
  }
  .category-head {
    display: block;
    width: 100%;
    color: #333;
  }
  .ul {
    color: #777;
    margin-top: 20px;
  }
  .listGroup {
    padding: 0;
    border: 0px;
  }
  a {
    color: #aaa;
    display: block;
    padding: 8px 0;
    text-decoration: none;
    &.active {
      color: #000;
    }
  }
}
.single-post {
  .category-head {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    color: #4dd0bb !important;
  }
  .card-post {
    box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
    -webkit-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
    -moz-box-shadow: 1px 9px 37px -8px rgba(75, 72, 77, 0.29);
    padding: 30px;
    .text-description {
      overflow: auto !important;
      max-height: 100% !important;
      figure {
        display: flex;
        justify-content: center;
      }
      img {
        display: inline-block;
        max-width: 100%;
        height: 100%;
      }
      blockquote {
        padding: 20px;
        background-color: #2b9684;
        border-radius: 4px;
        border-left: 8px solid #4dd0bb;
        color: #fff !important;
        &.has-text-align-center {
          * {
            text-align: center;
          }
        }
        p {
          margin: 0;
        }
        a {
          color: #ffffff;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .text-description {
    color: #3f4243;
  }
}

@media only screen and (max-width: 769px) {
  #header {
    .header-wrap {
      display: block !important;
      text-align: center;
      a {
        display: block !important;
      }
      .right-message {
        margin-top: 16px;
        display: block !important;
      }
    }
  }
  #intro {
    height: 300px;
    padding-bottom: 60px;
  }
  #categorie {
    .row {
      grid-template-columns: 1fr;
    }
    a {
      &:nth-child(1) {
        margin-bottom: 6px !important;
      }
    }
  }
  .intro-form {
    button {
      font-size: 14px !important;
    }
  }
  .content-wrap {
    display: block;
    .col-9 {
      width: 100% !important;
    }
    .text-description {
      display: block;
      margin-bottom: 10px;
    }
    .card-text {
      margin-top: 10px;
    }
  }
  #footer {
    padding: 30px 0;
    .left {
      padding: 0;
      margin: 20px 0;
      div {
        text-align: center !important;
      }
    }
    .wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        text-align: center;
        padding: 20px !important;
      }
    }
  }
  .written-by {
    div {
      font-size: 14px !important;
    }
  }
  #categorie {
    .row {
      gap: 20px !important;
    }
  }
  .floating-results {
    a {
      font-size: 14px;
    }
  }
}

.post-date {
  display: block;
  margin: 0 !important;
  h5 {
    text-align: center;
    margin: 0 !important;
    font-weight: 400;
    font-size: 14px !important;
    color: #4c4b53;
  }
}
.written-by {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0 0;
  border-top: 1px solid #eeeeee;
  img {
    width: 60px;
    height: 60px;
    margin-right: 16px;
    border-radius: 50%;
  }
  div {
    color: #444 !important;
    span {
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 994px) {
  .archive-posts-row {
    .category-wrap {
      order: 1;
    }
    .post-wrap {
      order: 0;
    }
  }
}

#box-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e8e7ea;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #loader {
    margin: 0 auto;
    margin-bottom: 8px;
    width: 40px;
    height: 40px;
    border: 4px solid rgb(104, 104, 104);
    border-radius: 100% !important;
    border-left-color: transparent;
    animation: loading 2.7s infinite;
  }
  .loader-message {
    color: rgb(104, 104, 104);
  }
}

@keyframes loading {
  to {
    transform: rotate(2turn);
  }
}

.no-result {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  font-size: 25px;
  font-weight: 500;
  color: #999;
}

.goback {
  display: flex;
  align-items: center;
  font-size: 18px;
  border: 0;
  padding: 16px;
  color: #333;
  background-color: transparent;
  &:hover {
    color: $primary;
  }
  .text {
    margin-left: 8px;
  }
}
